import { ProductName } from '../types';
import type { ExcludeProducts } from '../types';

//TODO:  Закомментированно до реализации сквозной авторизации
// const PRODUCT_URL = window.envUrls.PRODUCT_URL;

// export const productLink: Record<ExcludeProducts, string> = {
//   [ProductName.MITE]: `${PRODUCT_URL}/lk?personalization=personal_mite`,
//   [ProductName.APARTMENT]: `${PRODUCT_URL}/lk?personalization=personal_apartment`,
//   [ProductName.HOUSE]: `${PRODUCT_URL}/lk?personalization=personal_house`,
//   [ProductName.PET]: `${PRODUCT_URL}/lk?personalization=personal_pets`,
//   [ProductName.SPORT]: `${PRODUCT_URL}/lk?personalization=personal_sport-ns`,
//   [ProductName.TRAVEL]: `${PRODUCT_URL}/abroad/`,
// };

//TODO: Тест без сквозной авторизации
const WEB_LK_URL = window.envUrls.WEB_LK_URL;
const VZR_URL = window.envUrls.VZR_URL;

export const productLink: Record<ExcludeProducts, string> = {
  [ProductName.MITE_SUBSCRIBE]: `${WEB_LK_URL}/?product=mite&personalization=pulse_mite`,
  [ProductName.APARTMENT]: `${WEB_LK_URL}/?product=apartment&personalization=pulse_apartment`,
  [ProductName.HOUSE]: `${WEB_LK_URL}/?product=house&personalization=pulse_house`,
  [ProductName.PET]: `${WEB_LK_URL}/?product=pets&personalization=pulse_pets`,
  [ProductName.SPORT]: `${WEB_LK_URL}/?product=sport-ns&personalization=pulse_sport-ns`,
  [ProductName.TRAVEL]: VZR_URL,
};
