export const phoneRegExp =
  /^(\+7|8)?[\s\-]?\(?[4890][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;

export const phoneRegExpRemoveValueCasting = (value: string) =>
  `+7${value?.replace(/[\\(\\)\- ]/g, '') || ''}`;

export const phoneRegExpDisplayValueCasting = (value: string) =>
  `+ 7 ${value.replace(/(.{2})(\d{3})(\d{3})(\d{2})(\d{2})/g, '$1 ($2) $3-$4-$5')}`;

export const phoneRegExpDisplayValueForm = (value: string) => {
  const cleaned = ('' + value).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1,3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3] + '-' + match[4];
  }

  return '';
};
export const phoneRemoveCastingRegExp = /[\\(\\)\- ]/g;

export const phoneDisplayCastingRegExp = /(.{2})(\d{3})(\d{3})(\d{2})(\d{2})/g;

export const phoneDisplayFormRegExp = /^(\d{1,3})(\d{3})(\d{2})(\d{2})$/;
