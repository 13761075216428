import { useTranslation } from 'react-i18next';
import PulseLogoIcon from '@shared/assets/icons/pulse-app-logo.svg?react';
import { NavLinkAsButton } from '@shared/ui';
import {
  StyledMainBox,
  StyledBox,
  StyledTitle,
  StyledDescription,
} from './promo-header.styles';

interface PromoHeaderProps {
  className?: string;
}

export const PromoHeader = (props: PromoHeaderProps) => {
  const { className } = props;
  const { t } = useTranslation();

  const downloadLink = window.envUrls.INSURANCE_MOBILE_APP_HOT_LINK;

  return (
    <StyledMainBox className={className}>
      <StyledBox alignItems='center' gap={8}>
        <PulseLogoIcon />
        <StyledBox direction='column'>
          <StyledTitle>
            {t('COMMON:pulse.pulse')}. {t('COMMON:pulse.digitalInsurance')}
          </StyledTitle>
          <StyledDescription>
            {t('COMMON:downloads.downloadApp')}
          </StyledDescription>
        </StyledBox>
      </StyledBox>
      <NavLinkAsButton target='_blank' variant='mobile' to={downloadLink}>
        {t('COMMON:button.open')}
      </NavLinkAsButton>
    </StyledMainBox>
  );
};
