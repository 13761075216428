import { useGetQuery } from '@shared/api';
import { Endpoints } from '@shared/const';
import type { ProductResponse } from '../types';

export const useFetchProduct = () => {
  const fetchResult = useGetQuery<null, ProductResponse>({
    url: Endpoints.BUSINESS_LINES,
    keys: ['business-lines'],
  });

  return fetchResult;
};
