import { PluralRules } from '@shared/const';
import i18n from '@app/i18n/config';
import type { InsuredPerson } from '@shared/types';

export const convertPerson = (
  person?: InsuredPerson[],
  isFullName?: boolean,
) => {
  if (!person) return;

  if (person.length === 1) {
    const p = person[0];

    if (!p.firstName && !p.lastName) return;

    if (!p.middleName && isFullName) {
      return `${p.lastName} ${p.firstName}`;
    }

    if (isFullName) {
      return `${p.lastName} ${p.firstName} ${p.middleName}`;
    }
    return p.firstName;
  }

  const source: Record<string, string> = {
    one: i18n.t('INSURANCE_DETAIL:declensions.person-1'),
    few: i18n.t('INSURANCE_DETAIL:declensions.person-2'),
    many: i18n.t('INSURANCE_DETAIL:declensions.person-3'),
  };
  return `${person.length} ${source[PluralRules.select(person.length)]}`;
};
