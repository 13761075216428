import { Trans, useTranslation } from 'react-i18next';
import { Description } from '../common';
import { formattingDateByRu, openMobileAppLink } from '@shared/utils';
import { useStores } from '@shared/lib/mobx';
import { StyledLinkButton } from '../../shared';

export const RenewSubscriptionModal = () => {
  const { t } = useTranslation();
  const {
    GlobalStore: {
      insuranceStore: { currentEndsAt },
    },
  } = useStores();

  return (
    <>
      <Description>
        <Trans
          values={{ date: formattingDateByRu(currentEndsAt, 'short') }}
          components={{
            button: <StyledLinkButton onClick={openMobileAppLink} />,
          }}
        >
          {t('INSURANCE_DETAIL:text.renewSubscription')}
        </Trans>
      </Description>
    </>
  );
};
