import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosInstance } from '@shared/utils';
import type {
  QueryKey,
  QueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import type { AxiosRequestConfig } from 'axios';
import type { ErrorResponse } from '../api.types';
import { MethodApi } from '../api.types';

export const usePostMutation = <Request, Response>({
  url,
  config,
  options,
}: {
  url?: string;
  config?: AxiosRequestConfig<Request>;
  options?: UseMutationOptions<Response, ErrorResponse, Request>;
}) =>
  useMutation({
    mutationFn: async data => {
      const result = await axiosInstance({
        url,
        method: MethodApi.POST,
        data,
        ...config,
      });
      return result.data;
    },
    ...options,
  });

export const useGetQuery = <Request, Response>({
  url,
  keys,
  config,
  staleTime,
}: {
  url: string;
  keys: QueryKey;
  config?: AxiosRequestConfig<Request>;
  options?: QueryOptions<Request, ErrorResponse, Response>;
  // TODO: staleTime ломает кеширование
  staleTime?: number;
}) => {
  return useQuery<Response, ErrorResponse>({
    staleTime,
    queryKey: keys,
    queryFn: async () => {
      const result = await axiosInstance({
        method: MethodApi.GET,
        url,
        ...config,
      });

      return result.data;
    },
  });
};
